import App from '../components/App'
import FirstBlock from '../organisms/FamilySeo/FirstBlock'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HowToFind from '../organisms/FamilySeo/HowToFind'
import HowWillBe from '../organisms/FamilySeo/HowWillBe'
import LastBlock from '../organisms/FamilySeo/LastBlock'
import NextSessions from '../organisms/FamilySeo/NextSessions'
import React from 'react'
import SEO from '../atoms/SEO'
import SearchTitle from '../organisms/FamilySeo/SearchTitle'
import SecondBlock from '../organisms/FamilySeo/SecondBlock'
import ThirdBlock from '../organisms/FamilySeo/ThirdBlock'
import WhyHelp from '../organisms/FamilySeo/WhyHelp'
import styled from 'styled-components'
import { CommentsSection } from '../organisms/CommentsSection'
import { FamilyPsychologists } from '../organisms/FamilySeo/FamilyPsychologists'
import { HowItSection } from '../organisms/HowItSection'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background: #e6f0fd;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const FamilyPsychologist = ({ data }) => (
  <App>
    <SEO
      description="Онлайн консультации семейного психолога ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Подбор и консультация семейного психолога онлайн — помощь с отношениями в семье | YouTalk"
    />
    <Page>
      <Header />
      <Main>
        <FirstBlock />
        <SecondBlock />
        <ThirdBlock />
        <HowToFind />
        <WhyHelp />
        <HowWillBe />
        <NextSessions />
        <SearchTitle />
        <FamilyPsychologists
          data={pick(['psychologists', 'workAreas'], data)}
        />
        <HowItSection />
        <CommentsSection />
        <LastBlock />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default FamilyPsychologist

export const query = graphql`
  query FamilyPsychologist {
    workAreas: allWorkAreasData(
      filter: {
        tag: {
          in: [
            "Семейные отношения"
            "Детско-родительские отношения"
            "Беременность, роды, репродуктивные вопросы"
          ]
        }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    psychologists: allPsychologistsData(
      filter: {
        work_areas: {
          elemMatch: {
            tag: {
              in: [
                "Семейные отношения"
                "Детско-родительские отношения"
                "Беременность, роды, репродуктивные вопросы"
              ]
            }
          }
        }
        is_active: { eq: true }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
